import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { DottedLine, Icon, useTheme} from '@shapeable/ui';
import { PulseLoader as Spinner } from 'react-spinners';
import { classNames } from '@shapeable/utils';

const cls = classNames('content-tab');

// -------- Types -------->

// Ideal icon size for plus-tabs is 70 to match font size.
export type ContentTabVariant = 'section' | 'suiss' | 'plus-tabs';

export type ContentTabProps = Classable & HasChildren & HasStyle & {
  isActive?: boolean;
  nextIsActive?: boolean;
  isLoading?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ContentTabVariant;
  count?: number;
  hasIconLabel?: boolean;
  label?: string;
  icon?: React.ReactNode;
  color?: string;
}

export const ContentTabDefaultProps: ContentTabProps = {
  isActive: false,
  isLast: false,
  isFirst: false,
  variant: 'suiss',
};

// -------- Child Component Props -------->

type ContainerProps = {
  isActive: boolean;
  isLast: boolean;
  variant: ContentTabVariant;
  color?: string;
}

type CountProps = {
  count: number;
  variant: ContentTabVariant;
}

type IconProps = {
  isActive: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    flex-wrap: nowrap;
    box-sizing: border-box;
    font-weight: 400; 
    padding: 0;
    flex-grow: 1;
    color: var(--shapeable-mid-grey, #969696);
    text-align: center;
    font-family: ${theme.FONT('sans')};
    background-color: ${theme.COLOR('light')};
    border: none;
    height: ${theme.UNIT(14)};
    font-size: ${theme.FONT_SIZE(14)}; 

    ${({ color }: ContainerProps ) => color && css`
      &:hover {
        color: ${theme.COLOR(color || 'link-hover')};
        ${theme.FILL(color || 'link-hover')};
      }
    `}

    ${({ variant, color }: ContainerProps ) => variant === 'section' && css`
        color: ${theme.COLOR('text')};
        height: ${theme.UNIT(15)};
        border-top: 2px dotted transparent;
        border-right: 2px dotted ${theme.COLOR('line-semi-subtle')};
        border-bottom: 2px dotted ${theme.COLOR('line-semi-subtle')};
        
        &:last-child {
          border-right: none;
        }

        &:hover {
          color: ${theme.COLOR('link-hover')};
        }
    `}

    ${({ isActive }: ContainerProps ) => isActive && css`
      cursor: default;
      svg {
        margin-top: -1px;
      }
      &:hover {
        color: ${theme.COLOR('dark')};
        ${theme.FILL('dark')};
      }
    `}
    
    ${({ isActive, variant }: ContainerProps ) => isActive && variant === 'section' && css`
      font-weight: 500; 
      cursor: default;
      border-bottom-color: transparent;
      border-top: 2px dotted ${theme.COLOR('line-semi-subtle')};
      color: ${theme.COLOR('dark')};

      &:hover {
        color: ${theme.COLOR('dark')};
        background-color: ${theme.COLOR('light')};
      }
    `}

    ${({ variant, color }: ContainerProps ) => variant === 'suiss' && css`
      border-bottom: 1px solid ${color};
      padding-bottom: ${theme.UNIT(3)};
      color: ${theme.COLOR('text')};

      &:hover {
        color: var(--shapeable-strong, ${color});
      }
    `}

    ${({ variant, isActive, color }: ContainerProps ) => isActive && variant === 'suiss' && css`
      border-bottom: 4px solid ${color};
      padding-bottom: ${theme.UNIT(2)};
      font-weight: 500;

      &:hover {
        color: ${theme.COLOR('text')};
      }
    `}

    ${({ variant }: ContainerProps ) => variant === 'plus-tabs' && css`
      color: ${theme.COLOR('dark')};
      height: ${theme.UNIT(24)};
      font-size: 1em;
      border-top: 2px solid transparent;
      border-right: 2px solid ${theme.COLOR('line-semi-subtle')};
      border-bottom: 2px solid ${theme.COLOR('line-semi-subtle')};
      ${theme.FILL('line-semi-subtle')};

      

      &:hover {
        color: ${theme.COLOR('primary')};
      }
    `}

    ${({ isActive, variant }: ContainerProps ) => isActive && variant === 'plus-tabs' && css`
      font-weight: 500; 
      cursor: default;
      border-bottom-color: transparent;
      border-top: 2px solid ${theme.COLOR('line-semi-subtle')};
      color: ${theme.COLOR('dark')};
      ${theme.FILL('dark')};

      &:hover {
        color: ${theme.COLOR('dark')};
        background-color: ${theme.COLOR('light')};
      }
    `}
  `,
    desktop: css`
      ${({ variant }: ContainerProps ) => variant === 'plus-tabs' && css`
      font-size: 0.8em;
  `}
    `,
    desktopLarge: css`
      ${({ variant }: ContainerProps ) => variant === 'plus-tabs' && css`
        font-size: 1em;
    `}
  `,
});

const LabelStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    flex-grow: 1;
    padding: 0 ${theme.UNIT(3)};
    gap: ${theme.UNIT(1)};
  `,
});

const ContentTabCountStyles = breakpoints({
  base: css`
    ${({ count }: CountProps ) => (count && css`
      color: var(--shapeable-theme-primary, #FCAF18);
    `
    )}
    ${({ variant }: CountProps ) => variant === 'suiss' && css`
      color: var(--shapeable-theme-primary, #5CACC3);
    `}
  `,
});

const DottedLineStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 1;
  `,
});

const SpinnerStyles = breakpoints({
  base: css`
    
  `,
});

const LabelAndIconStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(3)};
  `,
});

const IconLabelStyles = breakpoints({
  base: css`
  white-space: normal; 
  word-wrap: break-word; 
  text-align: start;
  width: 100%;
  align-text: start !important;
  `,
});

const IconStyles = breakpoints({
  base: css`
    ${theme.FILL('#969696')};

  `,
});



// -------- Components -------->

const My = {
  Container: styled.button<ContainerProps>`${ContainerStyles}`,
    Label: styled.span`${LabelStyles}`,
    Spinner: styled(Spinner)`${SpinnerStyles}`,
    DottedLine: styled(DottedLine)`${DottedLineStyles}`,
    ContentTabCount: styled.span<CountProps>`${ContentTabCountStyles}`,

    LabelAndIcon: styled.div`${LabelAndIconStyles}`,
      Icon: styled(Icon)<IconProps>`${IconStyles}`,
      IconLabel: styled.span`${IconLabelStyles}`,
};

export const ContentTab: Shapeable.FC<ContentTabProps> = (props) => {
  const { className, children, isActive, nextIsActive, onClick, isLast, isFirst, variant, count, isLoading, 
    hasIconLabel, icon, label, color } = props;

  const theme = useTheme();

  const ContentTabContent = hasIconLabel ? (
    <My.LabelAndIcon>
      {icon}
      <My.IconLabel>{label}</My.IconLabel>
    </My.LabelAndIcon>
  ) : (
    children
  );

  return (
    <My.Container onClick={onClick} color={color} isActive={isActive} variant={variant} isLast={isLast} className={cls.name(className)}>
      <My.Label>
        {ContentTabContent}
        {
          isLoading ?
          <Spinner color={theme?.color?.primary} size={6}  />
          : (
            !!count && (variant === 'section' || variant === 'suiss') && 
            <My.ContentTabCount count={count} variant={variant}>({count})</My.ContentTabCount>
          )
        }
      </My.Label>
    </My.Container>
  )
};

ContentTab.cls = cls;
ContentTab.defaultProps = ContentTabDefaultProps;