import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Innovation } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, HeaderDivider, Icon, useEntity, useLang, useLink } from '@shapeable/ui';
import { HorizonTable } from './horizon-table';
import { InnovationIcon } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
const cls = classNames('innovation-horizon-table');

// -------- Types -------->

export type InnovationHorizonTableProps = Classable & HasChildren & { 
  entity?: Innovation;
};

export const InnovationHorizonTableDefaultProps: Omit<InnovationHorizonTableProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;

    a {
      color: ${theme.COLOR('text')};
      text-decoration: none;
    }

  `,
});

const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid #E5E5E5;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: #969696;
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 106.6%; /* 13.858px */
    margin-bottom: 8px;
  `,
});

const DividerStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    padding-right: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  `,
});

const IconAndTitleStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  `,
});

const IconContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
  `,
});

const InnovationIconStyles = breakpoints({
  base: css`
    ${theme.FILL('dark')}
  `,
});

const InnovationLabelStyles = breakpoints({
  base: css`
    flex: 1 0 0;
    color: #000;
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.6%; /* 20.628px */
    letter-spacing: -0.18px;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
   p {
    margin: 0;
   }
  `,
});

const HorizonStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div`${LayoutStyles}`,
      Header: styled.div`${HeaderStyles}`,
        Title: styled.span`${TitleStyles}`,
        Divider: styled(HeaderDivider)`${DividerStyles}`,
      Body: styled.div`${BodyStyles}`,
        IconAndTitle: styled.div`${IconAndTitleStyles}`,
          IconContainer: styled.div`${IconContainerStyles}`,
            InnovationIcon: styled(InnovationIcon)`${InnovationIconStyles}`,
          InnovationLabel: styled.span`${InnovationLabelStyles}`,
        Description: styled(ContentNode)`${DescriptionStyles}`,

    Horizon: styled(HorizonTable)`${HorizonStyles}`,
};

export const InnovationHorizonTable: Shapeable.FC<InnovationHorizonTableProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, name, horizons } = entity;

  const { Link } = useLink();

  const t = useLang();

  return (
   <My.Container className={cls.name(className)}>
      <My.Layout>
        <My.Header>
          <My.Title>{t('INNOVATION:')}</My.Title>
        </My.Header>
        <Link href={entity.path}>
        <My.Body>
          <My.IconAndTitle>
            <My.IconContainer>
              {/* <My.InnovationIcon height='46' width='46' viewBox='0 0 72 72'/> */}
              <My.InnovationIcon />
            </My.IconContainer>
            <My.InnovationLabel>{name}</My.InnovationLabel>
          </My.IconAndTitle>
          <My.Description entity={description as TContentNode} maxWords={20}/>
        </My.Body>
        </Link>
      </My.Layout>
      <My.Horizon items={horizons} />
   </My.Container>
  )
};

InnovationHorizonTable.defaultProps = InnovationHorizonTableDefaultProps;
InnovationHorizonTable.cls = cls;