import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Post, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Topic } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentNode, EntityFilters, NextPageLink, useEntity, DottedLine, useView, EntityMainHeader, EntityValue, EntityBanner, EntityGrid } from '@shapeable/ui';
import { InnovationHorizonTableList } from './innovation-horizon-table-list';
const cls = classNames('topic-main-layout');
import { compact } from 'lodash';
import { ExplorerView } from '../elements/explorer-view';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { classNames } from '@shapeable/utils';
import { TabSpec, Tabs } from '../elements/tabs';
import { ContentTabs } from '../elements/content-tabs';
import { AiCIconGlyph, AiUpperCaseIconGlyph } from '@shapeable/icons';
import { GptLayout } from '@shapeable/gpt';

// -------- Types -------->

export type TopicView = 'copper-ai' | 'deep-dive' | 'innovations' | 'library';
export type TopicMainLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicMainLayoutDefaultProps: Omit<TopicMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
  `,
});

const BannerContainerStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(5)};
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ModeratorsStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(5)};
    margin-top: ${theme.UNIT(5)};
  `,
});

const ModeratorStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(5)};
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const InnovationTableStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em; 
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
  tablet: css`
    height: calc(100vh - 312px);
  `,
  desktop: css`
    height: calc(100vh - 366px);
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const EntityGridStyles = breakpoints({
  base: css`
    .shp--card {
      background-color: ${theme.COLOR('light')};
    }
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    BannerContainer: styled.div`${BannerContainerStyles}`,
      Banner: styled(EntityBanner)`${BannerStyles}`,
        Moderators: styled.div`${ModeratorsStyles}`,
          Moderator: styled(EntityValue)`${ModeratorStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Body: styled.div`${BodyStyles}`,
      InnovationTable: styled(InnovationHorizonTableList)`${InnovationTableStyles}`,
      
      EntityGrid: styled(EntityGrid)`${EntityGridStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,
      Tabs: styled(ContentTabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

};

export const TopicMainLayout: Shapeable.FC<TopicMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const { feedEntries = [], pages = [], posts = [], citations = [], moderators = [], 
    color, description } = entity;

  const augmentedPosts = posts.map((post: Post) => {
    const postType = post?.type?.name;
    return {
      ...post,
      schema: {
        label: postType,
        pluralLabel: postType,
      }
    }
  })

  const libraryItems = compact([...augmentedPosts, ...feedEntries, ...pages, ...citations ]);

  const hasLibrary = !!libraryItems.length;

  const view = `topic-${entity.slug}`;
  const { filters } = useView(view);
  const { technologies = [], catalysts = [], horizonTypes = [] } = filters;
  const { selects } = useInnovationsFilters('topic');

  const hasModerators = !!moderators.length;

  const tabs: TabSpec<TopicView>[] = compact([
    {
      id: 'copper-ai',
      icon: <AiUpperCaseIconGlyph size={30} />
    },
    {
      id: 'deep-dive',
      label: 'Deep Dive',
    },
    !!entity?.innovations?.length && {
      id: 'innovations',
      label: 'Innovations',
    },
    hasLibrary && {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<TopicView>(view, 'deep-dive');
  const innovations = entity.innovations.filter(innovation => (
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  ))
  
  return (
    <My.Container className={cls.name(className)}>
      <My.BannerContainer>
        <My.Banner 
          variant='color'
          entityValueProps={{ showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}}
          >
          {
            hasModerators &&
            <My.Moderators>
              {
                moderators.map(moderator => <My.Moderator key={moderator.id} variant='person' showLabel={false} subtitle={moderator?.position} subHeading={moderator?.organisation.name} entity={moderator} /> )
              }
            </My.Moderators>
          }
        </My.Banner>
      </My.BannerContainer>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} ContentTabs={tabs} color={color?.value} variant='suiss' />
      }
      <ExplorerView>
      {
        activeView === 'copper-ai' && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        activeView === 'deep-dive' &&
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} /> 
          <DottedLine height={2} />
          <My.NextLink />
        </My.Section>
      }
      {
        activeView === 'innovations' &&
        <My.Section>
          <My.Filters
            results={entity.innovations} 
            view={view}
            selects={selects} 
          />
          <My.Body>
            <My.InnovationTable items={innovations} />
            <DottedLine height={2} />
            <My.NextLink />
          </My.Body>
          
        </My.Section>
      }
      {
        activeView === 'library' && 
        <My.Section>
          <My.EntityGrid items={libraryItems} />
        </My.Section>
      }
      </ExplorerView>

    </My.Container>
  )
};

TopicMainLayout.defaultProps = TopicMainLayoutDefaultProps;
TopicMainLayout.cls = cls;