import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { TabsContainer, useActiveEntity, useLang, useLink } from '@shapeable/ui';
import { compact } from 'lodash';
import { ContentTab, ContentTabVariant } from './content-tab';
import { classNames } from '@shapeable/utils';
const cls = classNames('content-tabs');


// -------- Types -------->

export type ContentTabspec<IdType extends string = string> = {
  id: IdType;
  label?: string;
  isLoading?: boolean;
  count?: number;
  icon?: React.ReactNode;
}

// -------- Props -------->

export type ContentTabsProps = Classable & HasChildren & {
  ContentTabs: ContentTabspec[];
  onTabClick?: (tab: string) => void;
  activeTab?: string;
  variant?: ContentTabVariant;
  color?: string;
}

export const ContentTabsDefaultProps: ContentTabsProps = {
  activeTab: 'all',
  ContentTabs: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
  `,
  desktop: css`
    padding: 0 ${theme.UNIT(6)};
    box-sizing: border-box;
  `,
});

const ContentTabstyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled(TabsContainer)<ContainerProps>`${ContainerStyles}`,
    Tab: styled(ContentTab)`${ContentTabstyles}`,
};

export const ContentTabs: Shapeable.FC<ContentTabsProps> = (props) => {
  const { className, onTabClick, ContentTabs, activeTab, variant, color } = props;

  const t = useLang();

  const handleClick = (tab: ContentTabspec) => () => {
    if (onTabClick) {
      onTabClick(tab.id);
    }
  };

  return (
    <My.Container showFade={false} className={cls.name(className)}>
      {
        compact(ContentTabs).map((tab, index) => {
          const nextTab = index < ContentTabs.length - 1 ? ContentTabs[index + 1] : null;
          const nextIsActive = nextTab && nextTab.id === activeTab;
          const isLast = index === ContentTabs.length - 1;
          const isFirst = index === 0;

          const hasLabelAndIcon = !!tab.label && !!tab.icon;

          return (
            <My.Tab 
              isLoading={tab.isLoading} 
              onClick={handleClick(tab)} 
              key={tab.id} count={tab.count} 
              nextIsActive={nextIsActive} 
              variant={variant} 
              isLast={isLast} 
              isFirst={isFirst} 
              isActive={activeTab === tab.id}
              hasIconLabel={hasLabelAndIcon}
              icon={tab.icon}
              label={tab.label}
              color={color}
              >
              {!hasLabelAndIcon && (tab.label || tab.icon)}
              </My.Tab>  
          );

        })
      }
    </My.Container>
  )
};

ContentTabs.cls = cls;
ContentTabs.defaultProps = ContentTabsDefaultProps;