import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable} from '@shapeable/types';
import { Innovation } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { InnovationHorizonTable } from './innovation-horizon-table';
import { classNames } from '@shapeable/utils';
const cls = classNames('innovation-horizon-table-list');

// -------- Types -------->

export type InnovationHorizonTableListProps = Classable & HasChildren & { 
  items: Innovation[];
};

export const InnovationHorizonTableListDefaultProps: InnovationHorizonTableListProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
  `,
});

const ItemStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    InnovationHorizonItem: styled(InnovationHorizonTable)`${ItemStyles}`,
};

export const InnovationHorizonTableList: Shapeable.FC<InnovationHorizonTableListProps> = (props) => {
  const { className, children, items } = props;
  
  return (
    <My.Container className={cls.name(className)}>
    {
      items.map(innovation => <My.InnovationHorizonItem key={innovation.id} entity={innovation} />)
    }
    {children}
    </My.Container>
  )
};

InnovationHorizonTableList.defaultProps = InnovationHorizonTableListDefaultProps;
InnovationHorizonTableList.cls = cls;