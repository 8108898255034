import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { TopicLayout } from '../../components/entities/topic-layout';

export default createGatsbyPageComponent('Topic', { layout: TopicLayout });

export const topicQuery = graphql`
  query TopicQuery($id: ID!) {
    platform {
      topic(id: $id) {
        __typename gptName _schema { label pluralLabel }
        id slug name __typename
        color { __typename id slug name value }
        outlineNumber
        trend { id slug name __typename }
        intro { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        innovations {
          __typename _schema { label pluralLabel }
          openGraph { description { text(truncate: { words: 20 }) } image { url } }
          id slug path name
          description { text }
          horizons { name id slug type { slug value } }
          catalysts { name id slug }
          technologies { name id slug }
        }
        embeds {
          citations {
            slug url name authors { id name slug } 
            authorShowsEtAl edition publication accessDate 
            startPage volume footnoteNumber year
          }
          posts {
            id name slug path
            openGraph {
            title description { plain }
            image { url type static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
            }
          }
          imageAssets {
            id
            slug
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        video {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        color { id name slug value darkValue veryDarkValue }
        banner {
          id
          image {
            id
            url
            static {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [800, 1440, 2880]
                )
              }
            }
          }
        }
        trend {
          id name slug __typename
          outlineNumber
          color { id name slug value }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug __typename url published websiteName summary { text }
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
        }
        description { text }
        subTopics {
          id name path outlineNumber slug __typename 
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          # indicatorValues {
          #   id value numericValue year
          #   indicator { id name slug dataSetId }
          # }
        }
        # globalExperts {
        #   __typename id name slug position
        #   photo { id url }
        #   organisation { id name slug }
        # }
        moderators {
          __typename id name slug position
          photo { id url }
          organisation { id name slug }
        }
        # connectedTrends {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedSubTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedPages {
        #   id name slug path type { name }
        # }
      }
    }
  }
`;
